@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-UltraBold.woff2') format('woff2'),
    url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-UltraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Light.woff2') format('woff2'),
    url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Bold.woff2') format('woff2'),
    url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Medium.woff2') format('woff2'),
    url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Thin.woff2') format('woff2'),
    url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Pro Display';
  src: url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Regular.woff2') format('woff2'),
    url('/assets/fonts/GTEestiProDisplay/GTEestiProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
