@import '../../@pages/styles/var';

$block-title: #01d0bd;

$white: #fff;

$greyC: #ccc;

$gray-basic: #bfbfbf;

:root {
  // --primary: $color-primary-gradient;
  --primary: #00d0b3;
  --secondary: $color-secondary;
}
